<script setup lang="ts">
import CornerLogo from '@/components/CornerLogo.vue';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import { useSeoMeta } from '@unhead/vue';
import { Head } from 'vike-vue/Head';
import { usePageContext } from 'vike-vue/usePageContext';

const pageContext = usePageContext();

useSeoMeta({
  title: pageContext.is404 ? '404 - Parsec Skateboards' : '500 - Parsec Skateboards',
});
</script>

<template>
  <Head>
    <title>404 - Parsec Skateboards</title>
    <meta name="description" content="Not Found" />
  </Head>

  <main>
    <Header />

    <section class="relative bg-primary md:!pt-20 col-span-12 rounded-lg flex justify-center">
      <CornerLogo class="hidden md:block" />

      <div v-if="pageContext.is404" class="max-w-4xl w-full flex flex-col items-center justify-center text-center h-72">
        <h1 class="text-[48px] font-bold">404</h1>
        <p class="text-2xl">Page not found</p>
      </div>

      <div v-else class="max-w-4xl w-full flex flex-col items-center justify-center text-center h-72">
        <h1 class="text-[48px] font-bold">500</h1>
        <p class="text-2xl">Internal server error</p>
      </div>
    </section>

    <Footer />
  </main>
</template>
